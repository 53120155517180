/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/reset.scss'
import './node_modules/flexboxgrid-sass/flexboxgrid.scss'
import './src/styles/animations.scss'
import './src/styles/style.scss'

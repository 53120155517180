// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tags-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\templates\\tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-post-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\templates\\post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thanks-js": () => import("C:\\Users\\joshn\\github\\zylo-gatsby\\src\\pages\\thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}


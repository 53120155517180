module.exports = [{
      plugin: require('C:/Users/joshn/github/zylo-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":640},
    },{
      plugin: require('C:/Users/joshn/github/zylo-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-111696753-1","anonymize":true},
    },{
      plugin: require('C:/Users/joshn/github/zylo-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/joshn/github/zylo-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
